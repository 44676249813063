
.list-urls>ul{
    margin: 0;
    padding: 0;
    list-style-type: none;

}



.grey-color{
    color: gray;
}

.grey-color:hover{
    color: black;
}

.pattern-i{
    height: 90vh;
    overflow-y: scroll;
}

.pattern-i::-webkit-scrollbar {
    display: none;
}

.pattern-ii{
    height: 90vh;
    overflow-y: scroll;
}

.pattern-ii::-webkit-scrollbar {
    display: none;
}

.list-urls>ul>li{
    font-size: 1rem;
    color: gray;
    cursor: pointer;
}

.list-collection>ul{
    margin: 0;
    padding: 0;
    list-style-type: none;

}

.list-collection>ul>li{
    font-size: 0.9rem;
    color: gray;
    cursor: pointer;
}

.colors-show>ul{
    list-style: none;
    display: flex;
    justify-content: center;
}

.colors-show>ul>li{
    margin-left: 1rem;
    padding: 1rem;
}

.color-circle-box{
    width: 10rem;
    height: 10rem;
    padding: 0.5rem;
    border: 0.1px solid rgb(35, 37, 37);
    border-width: 0.01rem;
    border-radius: 50%;
    margin: 1rem;
}

.down-like-link>ul{
    display: flex;
    justify-content: space-between;
    list-style: none;
}

.li{
    margin: 1rem;
    width: 6rem;
    height: 2rem;
    padding: 0.1rem;
    background-color: transparent;
    border: 1px solid rgb(35, 37, 37);
    border-radius: 14px;
}

.cursor{
    cursor: pointer;
}

.central-row{
    overflow-y: auto;
}

.logo-image-size{
    width: 3rem;
    height: 3rem;
}


#color-picker-card{
  width: 400px;
  border-radius: 0.8rem;
  background-color: rgb(136, 138, 138);
  height: 400px;
 
}

.medium-palette{
  width: 270px;
  border-radius: 0.8rem;
  background-color: rgb(136, 138, 138);
  height: 260px;

}

#show-pallet-1{
    position: relative;
    top: 115px;
    width: 5rem;
    background-color: rgba(0, 0, 0, 0.171);
    color: white;
    display: block;
    padding: 0.3rem;
}

#show-pallet-2{
    position: relative;
    top: 75px;
    width: 5rem;
    background-color: rgba(0, 0, 0, 0.171);
    color: white;
    padding: 0.3rem;
    display: block;
}


#show-pallet-3{
    position: relative;
    top: 45px;
    width: 5rem;
    background-color: rgba(0, 0, 0, 0.171);
    color: white;
    padding: 0.3rem;
    display: block;
}

#show-pallet-4{
    position: relative;
    top: 23px;
    width: 5rem;
    background-color: rgba(0, 0, 0, 0.171);
    color: white;
    border-bottom-left-radius: 0.8rem;
    padding: 0.3rem;
    display: block;
}

#show-pallet-1-color-on-hover{
    position: relative;
    top: 115px;
    width: 5rem;
    background-color: rgba(0, 0, 0, 0.171);
    color: white;
    padding: 0.3rem;
    display: none;
}

#show-pallet-2-color-on-hover{
    position: relative;
    top: 75px;
    width: 5rem;
    background-color: rgba(0, 0, 0, 0.171);
    color: white;
    padding: 0.3rem;
    display: none;
}


#none{
    display: none;
}

#show-pallet-3-color-on-hover{
    position: relative;
    top: 45px;
    width: 5rem;
    background-color: rgba(0, 0, 0, 0.171);
    color: white;
    padding: 0.3rem;
    display: none;
}

#show-pallet-4-color-on-hover{
    position: relative;
    top: 23px;
    width: 5rem;
    background-color: rgba(0, 0, 0, 0.171);
    color: white;
    border-bottom-left-radius: 0.8rem;
    padding: 0.3rem;
    display: none;
}

.medium-pallet-1{
    cursor: pointer;
    width: 270px;
    height: 100px;
    background-color: aqua;
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
  }
  
  .medium-pallet-2{
    cursor: pointer;
    background-color: aquamarine;
    width: 270px;
    height: 70px;
  }
  
  .medium-pallet-3{
    cursor: pointer;
    background-color: black;
    width: 270px;
    height: 50px;
  }
  
  
  .medium-pallet-4{
    cursor: pointer;
    width: 270px;
    background-color: blue;
    height: 40px;
    border-bottom-left-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
  }
  

.size{
    font-size: 0.7rem;
}

.pink{
    color: pink;
}


.collection-tags-button>ul{
    display: flex;
    list-style-type: none;
    justify-content: space-evenly;
}

.small-text{
    font-size: 0.8rem;
    color: rgb(87, 18, 94);
}

.collection-tags-button>ul>li{
    width: 3rem;
    height: 2rem;
    text-align: center;
    padding: 0.1rem;
    border-radius: 1rem;
    cursor: pointer;
    outline: auto;
    border-color: rgb(18, 102, 2);
    border-width: 0.4rem;
    background-color: transparent;
}

.mobile-view{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
